enum REDUX_MODAL_KEYS {
  BookMarkModal = "BookMarkModal",
  LearnCenter = "LearnCenter",
  DealManage = "DealManage",
  draftProfileChecklist = "draftProfileChecklist",
  draftUserChecklist = "draftUserChecklist",
  gptChat = "gptChat",
  // Notice keys
  completeProfileNotice = "completeProfileNotice",
  previewProfilesNotice = "previewProfilesNotice",
  managePreferencesNotice = "managePreferencesNotice",
  goLiveNotice = "goLiveNotice",
}

export default REDUX_MODAL_KEYS
